import {useMemo} from 'react';

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: any) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // total page numbers is first page + last page + 2* siblingCount + 2* dots + current page
    const totalPageNumbers = 2 * siblingCount + 5;

    const range = (start: number, end: number) => {
      const length = end - start + 1;
      return Array.from({length}, (_, idx) => idx + start);
    };

    // show full range if total number of page buttons is greater than the total number of available pages
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // calculate left and right sibling index - make sure within range
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    // don't show elipses when only 1 page between first and last page
    const shouldShowLeftElipses = leftSiblingIndex > 2;
    const shouldShowRightElipses = rightSiblingIndex < totalPageCount - 2;

    // no left elipses, but right elipses present
    if (!shouldShowLeftElipses && shouldShowRightElipses) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, 'DOTS', totalPageCount];
    }

    // no right elipses, but left elipses present
    if (shouldShowLeftElipses && !shouldShowRightElipses) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );

      return [1, 'DOTS', ...rightRange];
    }

    // both left and right elipses present
    if (shouldShowLeftElipses && shouldShowRightElipses) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);

      return [1, 'DOTS', ...middleRange, 'DOTS', totalPageCount];
    }

    return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
